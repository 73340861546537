import styled from "@emotion/styled"
import { CardContainer } from "../ui/cardContainer"
import { Logo } from "../ui/logo"
import { ErrorMessage, Input } from "../ui/input"
import { useEffect, useRef, useState } from "react"
import { Button } from "../ui/button"
import logo from "../img/logo.png";
import { useParams } from "react-router-dom"
import { User } from "../interface/user"
import { Formik, FormikProps } from "formik"
import { initPaymentSession } from "../api/payment"
import {Main} from "../layout";
import { getUserInfo } from "../api/user"

const ButtonAndInputContainer = styled.div`
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
`

const InfoContainer = styled.div`
    padding: 100px 0px 60px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
`
const TextTitle = styled.p`
    font-size: 36px;
    text-align: center;
    margin: 0;
    color: white;
    letter-spacing: 2px;
    font-family: "Gotham Pro", sans-serif;
    font-weight: bold;
`

const NicknameText = styled.p`
    font-size: 32px;
    color: #41FD13;
    letter-spacing: 2px;
    margin: 0;
    font-weight: bold;
    font-family: "Gotham Pro", sans-serif;
`

const InputForm = styled.form`
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 1.42vh;
    gap: 0.8vw;
    width: 100%;
`

const InputWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
`

export const PayerInfo = () => {

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const inputRef = useRef<HTMLInputElement | null>(null)
    const formikRef = useRef<FormikProps<FormProps>>(null);
    let [user, setUser] = useState<User | null>(null)

    let {userId} = useParams();

    useEffect(() => {
        if (userId) {
            getUserInfo(userId)
                .then((user) => {
                    setUser(user)
                })
                .catch((err) => {
                    console.log(err, formikRef.current)
                    formikRef.current?.setTouched({
                        amount: true
                    })
                    formikRef.current?.setErrors({
                        amount: "Ошибка получения данных пользователя"
                    })
                    const timer = setTimeout(() => {
                        formikRef.current?.setErrors({});
                        clearTimeout(timer);
                    }, 3000);
                })
        }
    }, [userId])

    interface FormProps {
        amount: number,
    }

    return <Main>
        <CardContainer>
            <Logo>
                <img src={logo} alt="logo"/>
            </Logo>
            <InfoContainer>
                <TextTitle>Пополнить баланс</TextTitle>
                <NicknameText>{user?.user.login || user?.user.phone}</NicknameText>
            </InfoContainer>
            <ButtonAndInputContainer>
                <Formik
                    innerRef={formikRef}
                    initialValues={{amount: 0}}
                    validateOnBlur={false}
                    onSubmit={(values) => {
                        if(values.amount === 0) {
                            formikRef.current?.setErrors({
                                amount: "Сумма пополнения не может быть пустой"
                            })
                            const timer = setTimeout(() => {
                                formikRef.current?.setErrors({});
                                clearTimeout(timer);
                            }, 3000);
                        }
                        else if (userId) {
                            initPaymentSession(values.amount, userId)
                                .then(({payment_url}) => {
                                    window.location.replace(payment_url)
                                })
                                .catch(() => {
                                    formikRef.current?.setErrors({
                                        amount: "Ошибка на сервере, повторите позже"
                                    })
                                    const timer = setTimeout(() => {
                                        formikRef.current?.setErrors({});
                                        clearTimeout(timer);
                                    }, 3000);
                                })
                        }
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                      }) => (
                        <InputForm onSubmit={handleSubmit}>
                            <InputWrapper>
                                <Input
                                    ref={inputRef}
                                    placeholder={"Введите сумму"}
                                    type="text"
                                    name="amount"
                                    onInput={(e) => {
                                        const {value} = e.target as unknown as { value: string };
                                        const regex = /^[0-9]*$/;
                                        if (regex.test(value)) {
                                            setFieldValue('amount', Number(value)).then();
                                        }
                                    }}
                                    value={values.amount}
                                    onBlur={handleBlur}
                                    maxLength={10}
                                    error={Boolean(errors.amount && touched.amount)}
                                />
                                {errors.amount && touched.amount && <ErrorMessage> {errors.amount}</ErrorMessage>}
                            </InputWrapper>
                        </InputForm>
                    )}
                </Formik>
                <Button onClick={(() => {
                    formikRef.current?.submitForm()
                })}>Оплатить</Button>
            </ButtonAndInputContainer>

        </CardContainer>
    </Main>


}